<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Переходим к основательной работе, будем создавать заготовки тела!</p>
            <h3 class="nomargin">Необходимые материалы:</h3>           
            <ul>
                <li>Трафареты (сделанные на предыдущем занятии)</li>
                <li>Пластилин</li>
                <li>Нож для пластилина (любой тупой ненужный)</li>
                <li>Бамбуковые палочки или шпажки</li>
                <li>Линейка, карандаш</li>
                <li>Фольга</li>
                <li>Молоток (опционально)</li>
                <li>Металлический гранулят</li>
            </ul>
            <h3>1. Лепим основу головы из пластилина</h3>
        </div>

        <VideoView video-id="6d4da2c3298543df9dd4a9c885974dd4"/>
        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Скатываем шар</li>
                <li>Усекаем его по бокам</li>
                <li>Надеваем на него маску</li>
                <li>Проверяем по чек-листу (ниже)</li>
            </ol>
            
            <p class="nomargin"><b>Чек-лист по голове:</b></p>
            <ul>
                <li>Все должно быть скругленным, плавным, никаких углов!</li>
                <li>Сверху голова напоминает яйцо (затылок расширяется, лоб сужается)</li>
                <li>Сзади должен быть шар </li>
                <li>Все объемы спереди стремятся к центру (условным рельсам)</li>
                <li>Верхний край глазницы проходит по линии середины головы</li>
                <li>Глазницы утоплены на одну и ту же глубину</li>
                <li>Проверьте голову на симметрию (проведите центральную линию и прокрутите)</li>
            </ul>

            <a @click="showImage('/files/BodoirV2/head.jpg')" class="checklist" :class="$mq">

                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>2. Лепим основу верхней части тела из пластилина</h3>
        </div>

        <VideoView video-id="755469c9a2ed477f8a91b26c1ec20c72"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Соединяем трафареты по центральным линиям</li>
                <li>Наполняем пластилином</li>
                <li>Проверяем по чек-листу (ниже)</li>
            </ol>

            <p class="nomargin"><b>Чек-лист по телу:</b></p>
            <ul>
                <li>Все должно быть скругленным, плавным, никаких углов!</li>
                <li>Рельефы трафарета должны просвечивать сквозь пластилин</li>
                <li>Шея сверху в имеет круглую форму, а не квадратную</li>
                <li>Нижний срез тела также скругленный (спереди более выпукло, сзади более плоско)</li>
                <li>Шея идет под наклоном, а не прямо входит в тело</li>
                <li>Плечо сверху округлое, а не острое</li>
                <li>Плечи на одном уровне и симметричны </li>
                <li>Проверьте заготовку на симметрию </li>
            </ul>

            <a @click="showImage('/files/BodoirV2/body.jpg')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-body.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <p class="nomargin"><b>2.2 Альтернативный способ создания заготовки верхней части тела</b></p>
            <p>Проще, но неэффективнее, в нем сложнее отследить симметрию плеч и объемы.</p>
        </div>

        <VideoView video-id="80241918a014419bbd0bf39b49729b0e"/>

        <div class="MBtextcont">
            <h3>3. Делаем заготовку нижней части тела из фольги</h3>
        </div>

        <VideoView video-id="5e031ae7c0084d19b767bacf8a0cf76a"/>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li><b>Голову</b>  из пластилина + проверить по чек-листу</li>
                <li><b>Верхнюю часть тела</b>  из пластилина + проверить по чек-листу</li>
                <li><b>Нижнюю часть тела</b>  из фольги с гранулятом</li>
                <li><b>Подготовить материалы</b> к следующему занятию: пластик LaDoll, рулон пищевой пленки, синтетическая кисть (плоская №4-6), палочки для суши, коктейльные трубочки, стек-гладилка, линейка с круглыми отверстиями</li>
            </ol>
            <h3><a href="/mycourses/homework">Как сдавать ДЗ куратору / в чат?</a></h3>
           <h3>Время выполнения: 2ч 30мин</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import KImage from "../../Widgets/KImage";
    import chapters from './data.js'

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage,
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src) {
                this.$swal.fire({
                    // width: '80%',
                    height: '90%',
                    background: '#eae2e0',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>